import { SEEDGREEN_BASE_URL, seedgreenAPI } from "../api/axios";
import { useQuery } from "@tanstack/vue-query";
import { AxiosError } from "axios";
let REDIRECT = true;
let REDIRECT_EXTENSION;
let LOGIN_PAGE_URL = SEEDGREEN_BASE_URL + "/Account/Login";
/**
 * Configure token verification behavior
 * @param options
 */
export function configure(options) {
    REDIRECT = options.redirect ?? true;
    if (REDIRECT && options.redirectExtension) {
        REDIRECT_EXTENSION = options.redirectExtension;
    }
    if (REDIRECT && options.loginPageUrl) {
        LOGIN_PAGE_URL = options.loginPageUrl;
    }
}
export function useVerifyToken() {
    const query = useQuery({
        queryKey: ["verify-token"],
        queryFn: async () => {
            try {
                const response = await seedgreenAPI.get("WebApiAuthentication/VerifyToken");
                return handleTokenResponse(response);
            }
            catch (error) {
                if (error instanceof AxiosError && error.response) {
                    return handleTokenResponse(error.response);
                }
                console.error("Unknown authentication error", error);
                return false;
            }
        },
        staleTime: 1 * 60 * 1000, // Re-validate authorized state on requests every minute
        refetchInterval: 5 * 60 * 1000, // Background-check authorized state every five minutes
        placeholderData: false,
    });
    return {
        tokenValid: query.data,
        ...query,
    };
}
/**
 * Redirects user to login if needed
 * @param response
 * @returns True if response indicates a valid token, false otherwise
 */
function handleTokenResponse(response) {
    if (response.status === 401 || response.status === 403) {
        if (REDIRECT) {
            let returnExtention = "";
            if (REDIRECT_EXTENSION)
                returnExtention = REDIRECT_EXTENSION + window.location.pathname;
            window.location.href = LOGIN_PAGE_URL + returnExtention;
        }
        return false;
    }
    return true;
}
